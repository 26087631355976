import { Link } from "react-router-dom";
import { Button, Header, Icon, Segment } from "semantic-ui-react";

export default function NotFound() {
    return(
        <Segment placeholder>
            <Header icon>
                <Icon name='search'/>
                    This page was not found. 
            </Header>
            <Segment.Inline>
                <Button as={Link} to='/activityes'>
                    Return to Activites.
                </Button>
            </Segment.Inline>
        </Segment>
    )
}