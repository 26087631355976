import React, { useEffect, useState } from "react";
import { Grid, Loader } from "semantic-ui-react";
import ActivityList from "./ActivityList";
import { useStore } from "../../../app/stores/store";
import { observer } from "mobx-react-lite";
import LoadingComponent from "../../../app/layout/LoadingComponent";
import ActivityFilters from "./ActivityFilters";
import { PaginParams } from "../../../app/models/pagination";
import InfiniteScroll from 'react-infinite-scroller';
import ActivityListItemPlaceholder from "./ActivityListItemPlaceholder";



export default observer( function ActivityDashbard() {

    const {activityStore} = useStore();
    const {loadActivities, activityRegistry, setPaginParams, pagination} = activityStore;
    const [loadingNext, setLoadingNext] = useState(false);

    function handleGetNext()
    {
        setLoadingNext(true);
        setPaginParams(new PaginParams(pagination!.currentPage + 1))
        loadActivities().then(() => setLoadingNext(false));
    }



    useEffect(() => {
     if(activityRegistry.size <= 1) loadActivities();
    }, [loadActivities])
          
          
          
    //if(activityStore.loadingInitial && !loadingNext) return <LoadingComponent content='Loading app' />

    return (
        <Grid>
            <Grid.Column width='10'>
                {activityStore.loadingInitial && !loadingNext ? (
                    <>
                        <ActivityListItemPlaceholder />
                        <ActivityListItemPlaceholder />
                    </>
                ): (
                    <InfiniteScroll
                        pageStart={0}
                        loadMore={handleGetNext}
                        hasMore={!loadingNext && !!pagination && pagination.currentPage < pagination.totalPages}
                        initialLoad={false}
                    >
                        <ActivityList />
                    </InfiniteScroll>
                )}


            </Grid.Column>
            <Grid.Column width={6}>
                <ActivityFilters />
            </Grid.Column>
            <Grid.Column width={10}>
                <Loader active={loadingNext}/>
            </Grid.Column>
        </Grid>
    )
})