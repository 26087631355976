import axios, { AxiosError, AxiosResponse } from 'axios';
import { Activity, ActivityFromValues } from '../models/activity';
import { toast } from 'react-toastify';
import { router } from '../router/Routes';
import { store } from '../stores/store';
import { User, UserFormValues } from '../models/user';
import { Photo, Profile, UserActivity } from '../models/profiles';
import { PaginatedResult } from '../models/pagination';

const sleep = (delay: number) => { //used to make fake delay!!!
    return new Promise((resolve) => {
        setTimeout(resolve, delay)
    })
}

axios.defaults.baseURL = process.env.REACT_APP_API_URL;

const responceBody = <T> (response: AxiosResponse<T>) => response.data;

axios.interceptors.request.use(conifg => {
    const token = store.commonStore.token;
    if(token && conifg.headers) conifg.headers.Authorization = `Bearer ${token}`;
    return conifg;
})

axios.interceptors.response.use(async response => { //used to add fake delay!!!
        if (process.env.NODE_ENV === 'development')
            await sleep(1000);//the fake delay
        const pagination = response.headers['pagination'];
        if (pagination) {
            response.data = new PaginatedResult(response.data, JSON.parse(pagination));
            return response as AxiosResponse<PaginatedResult<any>>
        }
        return response;
}, (error: AxiosError) => {
    const {data, status, config} = error.response as AxiosResponse;
    switch (status) {
        case 400:
            if (config.method === 'get' && data.errors.hasOwnProperty('id'))
            {
                router.navigate('/not-found');
            }
            if (data.errors)
            {
                const modalStateErrors = [];
                for (const key in data.errors)
                {
                    if(data.errors[key]) {
                        modalStateErrors.push(data.errors[key])
                    }
                }
                throw modalStateErrors.flat();
            } else {
                toast.error(data);
            }
            break;
        case 401:
            toast.error('unauthorised')
            break;  
        case 403:
            toast.error('forbidden')
            break;  
        case 404:
            router.navigate('not-found')
            break;  
        case 500:
            store.commonStore.setServerError(data);
            router.navigate('/server-error')
            break;  
    }
    return Promise.reject(error);
})

const requests = {
    get: <T> (url: string) => axios.get<T>(url).then(responceBody),
    post: <T> (url: string, body: {}) => axios.post<T>(url, body).then(responceBody),
    put: <T> (url: string, body: {}) => axios.put<T>(url, body).then(responceBody),
    del: <T> (url: string) => axios.delete<T>(url).then(responceBody),
}

const Activities = {
    list: (params: URLSearchParams) => axios.get<PaginatedResult<Activity[]>>('/activities', {params})
        .then(responceBody),
    details: (id: string) => requests.get<Activity>(`/activities/${id}`), //remeber use must use ` and not ' here or else
    create: (activity: ActivityFromValues) => requests.post<void>('/activities', activity),
    update: (activity: ActivityFromValues) => requests.put<void>(`/activities/${activity.id}`, activity),
    delete: (id: string) => requests.del<void>(`/activities/${id}`),
    attend: (id: string) => requests.post<void>(`/activities/${id}/attend`, {})
}

const Account = {
    current: () => requests.get<User>('/account'),
    login: (user: UserFormValues) => requests.post<User>('/account/login', user),
    register: (user: UserFormValues) => requests.post<User>('/account/register', user)
}

const Profiles = {
    get: (username: string) => requests.get<Profile>(`/profiles/${username}`),
    uploadPhoto: (file: Blob) => {
        let formData = new FormData();
        formData.append('File', file); //name must match the name in the api
        return axios.post<Photo>('photos', formData, {
            headers: {'Content-Type': 'multipart/form-data'}
        })
    },
    setMainPhoto: (id:string) => requests.post(`/photos/${id}/setMain`, {}),
    deletePhoto: (id:string) => requests.del(`/photos/${id}`),
    updateFollowing: (username: string) => requests.post(`/follow/${username}`, {}),
    listFollowing: (username: string, predicate: string) => 
        requests.get<Profile[]>(`/follow/${username}?predicate=${predicate}`),
    listActivites: (username: string, predicate: string) => 
        requests.get<UserActivity[]>(`/profiles/${username}/activities?predicate=${predicate}`)
}

const agent = {
    Activities,
    Account,
    Profiles
}

export default agent;