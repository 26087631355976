import { makeAutoObservable, reaction, runInAction } from "mobx";
import { Activity, ActivityFromValues } from "../models/activity";
import agent from "../api/agnet";
import { format } from "date-fns";
import { store } from "./store";
import { Profile } from "../models/profiles";
import { PaginParams, Pagination } from "../models/pagination";


export default class ActivityStore {

    activityRegistry = new Map<string, Activity>();
    selectedActivity: Activity | undefined = undefined;
    editMode = false;
    loading = false;
    loadingInitial = false;
    pagination: Pagination | null = null;
    paginParams = new PaginParams();
    predicate = new Map().set('all', true);


    constructor() {
        makeAutoObservable(this)

        reaction(
            () => this.predicate.keys(),
            () => {
                this.paginParams = new PaginParams();
                this.activityRegistry.clear();
                this.loadActivities();
            }
        )
    }

    setPaginParams = (paginParams: PaginParams) => {
        this.paginParams = paginParams;
    }

    setPredicate = (predicate: string, value: string | Date) => {
        const resetPredicate = () => {
            this.predicate.forEach((value, key) => {
                if (key !== 'startDate') this.predicate.delete(key);
            })
        }

        switch (predicate) {
            case 'all':
                resetPredicate();
                this.predicate.set('all', true);
            break;
            case 'isGoing':
                resetPredicate();
                this.predicate.set('isGoing', true);
            break;
            case 'isHost':
                resetPredicate();
                this.predicate.set('isHost', true);
            break;
            case 'startDate':
                this.predicate.delete('startDate');
                this.predicate.set('startDate', value);
            break;
        
        }
    }

    get axiosParams() {
        const params = new URLSearchParams();
        params.append('pageNumber', this.paginParams.pageNumber.toString());
        params.append('pageSize', this.paginParams.pageSize.toString());
        this.predicate.forEach((value, key) => {
            if(key === 'startDate') {
                params.append(key, (value as Date).toISOString())
            } else {
                params.append(key, value);
            }
        })
        return params;
    }

    get activitiesByDate(){ //call back funtion
        return Array.from(this.activityRegistry.values()).sort((a, b) => 
            a.date!.getTime() - b.date!.getTime());
    }

    get groupedActivities(){ //call back funtion
        return Object.entries(
            this.activitiesByDate.reduce((activities, activity) => {
                const date = format(activity.date!, 'dd MMM yyyy');
                activities[date] = activities[date] ? [...activities[date], activity] : [activity];
                return activities;
            }, {} as {[key: string]: Activity[]})
        )
    }



    loadActivities = async () => {
        this.setLoadingInitial(true);
        try {
            const result = await agent.Activities.list(this.axiosParams);
                result.data.forEach(activity => {
                    this.setActivity(activity);
                  })
                  this.setPagination(result.pagination)
                  this.setLoadingInitial(false);

        } catch (error)
        {
            console.log(error);
            this.setLoadingInitial(false);

        }
    }

    setPagination = (pagination: Pagination) => {
        this.pagination = pagination;
    }


    loadActivity = async (id: string) => {
        let activity = this.getActivity(id);
        if(activity) {
            this.selectedActivity = activity
            return activity;
        }
        else {
            this.setLoadingInitial(true);
            try {
                activity = await agent.Activities.details(id);
                this.setActivity(activity);
                runInAction(() =>  this.selectedActivity = activity)
                this.setLoadingInitial(false);
                return activity;
            } catch (error) {
                console.log(error);
                this.setLoadingInitial(false);
            }
        }
    }

    private setActivity = (activity: Activity) => {
        //activity.date = activity.date.split('T')[0]; //splitting date data at the time so it will be [0] is just the date and [1] is just the time info werid syntix but pretty easy to understand
        const user = store.userStore.user;
        // console.log(user)
        if(user) {
            activity.isGoing = activity.attendees!.some(
                a => a.username === user.userName
            )
            activity.isHost = activity.hostUsername === user.userName;
            // console.log("Activity.HostUsername = " + activity.hostUsername + " ||| The user.username = " + user.userName)
            activity.host = activity.attendees?.find(x => x.username === activity.hostUsername);
        }
        activity.date = new Date(activity.date!);
        this.activityRegistry.set(activity.id, activity);
    }

    private getActivity = (id: string) => {
        return this.activityRegistry.get(id);
    }


    setLoadingInitial = (state: boolean) => {
        this.loadingInitial = state;
    }


    createActivity = async(activity: ActivityFromValues) => {
        const user = store.userStore.user;
        const attendee = new Profile(user!);
        try {
            await agent.Activities.create(activity);
            const newActivity = new Activity(activity);
            newActivity.hostUsername = user!.userName;
            newActivity.attendees = [attendee];
            this.setActivity(newActivity);
            runInAction(() => {
                this.selectedActivity = newActivity;
            })
        } catch (error) {
            console.log(error);
        }
    }


    updateActivity = async (activity: ActivityFromValues) => {
        try {
            await agent.Activities.update(activity);
            runInAction(() => {
                if(activity.id){
                    let updatedActivity = {...this.getActivity(activity.id), ...activity}
                    this.activityRegistry.set(activity.id, updatedActivity as Activity);
                    this.selectedActivity = updatedActivity as Activity;
                }


            })
        } catch (error) {
            console.log(error);
        }
    }


    deleteActivity = async (id: string) => {
        this.loading = true;
        try {
            await agent.Activities.delete(id);
            runInAction(() => {
                this.activityRegistry.delete(id);
                this.loading = false;
            })
        } catch (error) {
            console.log(error);
            runInAction(() => {
                this.loading = false;
            })
            
        }
    } 

    updateAttendance = async () => {
        const user = store.userStore.user;
        this.loading = true;
        try {
            await agent.Activities.attend(this.selectedActivity!.id);
            runInAction(() => {
                if (this.selectedActivity?.isGoing) {
                    this.selectedActivity.attendees = 
                        this.selectedActivity.attendees?.filter(a => a.username !== user?.userName);
                    this.selectedActivity.isGoing = false;
                } else {
                    const attendee = new Profile(user!);
                    this.selectedActivity?.attendees?.push(attendee);
                    this.selectedActivity!.isGoing = true;
                }

                this.activityRegistry.set(this.selectedActivity!.id, this.selectedActivity!);

            })

        } catch (error)
        {
            console.log(error);
        } finally {
            runInAction(() => this.loading = false);
        }
    }

    cancleActivityToggle = async () => {
        this.loading = true;
        try {
            await agent.Activities.attend(this.selectedActivity!.id);
            runInAction(() => {
                this.selectedActivity!.isCancelled = !this.selectedActivity?.isCancelled;
                this.activityRegistry.set(this.selectedActivity!.id, this.selectedActivity!);
            })
        } catch (error) {
            console.log(error);
        } finally {
            runInAction(() => this.loading = false);
        }
    }

    clearSelectedActivity = () => {
        this.selectedActivity = undefined;
    }

    updateAttendeeFollowing = (username: string) => {
        this.activityRegistry.forEach(activity => {
            activity.attendees.forEach(attendee => {
                if(attendee.username === username) {
                    attendee.following ? attendee.followerCount -- : attendee.followerCount++;
                    attendee.following = !attendee.following;
                }
            })
        })
    }

}