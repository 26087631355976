import { observer } from "mobx-react-lite";
import React from "react";
import Calendar from "react-calendar";
import { Header, Menu } from "semantic-ui-react";
import { useStore } from "../../../app/stores/store";

export default observer( function ActivityFilters(){
    const {activityStore: {predicate, setPredicate}} = useStore();

    return(
        <>
            <Menu vertical size="large" style={{width: '100%', marginTop: 25}}>
                <Header icon='filter' attached color='red' content='Filters' />
                <Menu.Item 
                    content='All activites' 
                    active={predicate.has('all')}
                    onClick={() => setPredicate('all', 'true')}
                />
                <Menu.Item 
                    content="I'm Going"
                    active={predicate.has('isGoing')}
                    onClick={() => setPredicate('isGoing', 'true')}
                />
                <Menu.Item 
                    content="I'm hosting" 
                    active={predicate.has('isHost')}
                    onClick={() => setPredicate('isHost', 'true')}
                />
            </Menu>
            <Header />
            <Calendar 
                onChange={(date: any) => setPredicate('startDate', date)}
                value={predicate.get('startDate') || new Date()}
            />
        </>

    )
})