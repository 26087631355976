import { Profile } from "./profiles";

export interface Activity {
    id: string;
    title: string;
    date: Date | null;
    description: string;
    category: string;
    city: string;
    venue: string;
    hostUsername: string;
    isCancelled: boolean;
    isGoing: boolean;
    isHost: boolean;
    host?: Profile;
    attendees: Profile[];
  }

  export class Activity implements Activity {
    constructor(init?: ActivityFromValues) {
      Object.assign(this, init)
    }
  }
  
  export class ActivityFromValues {
    id?: string = undefined;
    title: string = '';
    category: string = '';
    description: string = '';
    date: Date | null = null;
    city: string = '';
    venue: string = '';

    constructor(activity?: ActivityFromValues) {
        if (activity) {
          this.id = activity.id;
          this.title = activity.title;
          this.category = activity.category;
          this.description = activity.description;
          this.date = activity.date;
          this.venue = activity.venue;
          this.city = activity.city;
        }
    }
  }